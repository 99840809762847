import React from "react";
import "./home.css";
import Footer from "../../components/Footer/footer";
import { Grid, Container } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
import { Helmet } from "react-helmet";

const HomePage = () => {

    return (
      <div className="switch-wrapper">
          <Helmet>
              <meta property="og:url" content="https://www.vocabriu.com" />
          </Helmet>
          <div id="home-hero">
              <Container>
                  <Grid item md={6} sm={12}>
                      <h1>
                          <FormattedMessage id="app.home.header.main" />
                      </h1>
                  </Grid>
              </Container>
          </div>
          <Footer />
      </div>
    );
};

export default HomePage;
