import React, { Component } from "react"
import "./css/Main.css"
import { Route, Redirect } from "react-router-dom"
import { AnimatedSwitch } from 'react-router-transition'
import HomePage from "./pages/Home/home"
import NotFoundPage from "./pages/404/404"
import Page from "./components/Page/page"
import PrivacyPolicyPage from "./pages/Documents/PrivacyPolicy/privacypolicy"
import TermsAndConditionsPage from "./pages/Documents/TermsAndConditions/termsandconditions"
import Consent from "./components/Consent/consent";

class App extends Component {
  render() {
    return (
      <>
        <AnimatedSwitch
          atEnter={{ opacity: 0, marginTop: 20}}
          atLeave={{ opacity: 0, marginTop: 0}}
          atActive={{ opacity: 1, marginTop: 0}}
          className="switch-wrapper"
        >
          <Route exact path="/" render={(props) => ( <Page title="Vocabriu"> <HomePage {...props} /> </Page> )} />
          <Route exact path="/privacy" render={(props) => ( <Page title="Vocabriu | Privacy Policy"> <PrivacyPolicyPage {...props} /> </Page> )} />
          <Route exact path="/terms" render={(props) => ( <Page title="Vocabriu | Terms & Conditions"> <TermsAndConditionsPage {...props} /> </Page> )} />
          <Route exact path="/404" render={(props) => ( <Page title="Vocabriu | 404"> <NotFoundPage {...props} /> </Page> )} />
          <Redirect to="/404" />
        </AnimatedSwitch>
        <Consent />
      </>
    )
  }
}

export default App
